import styled from 'styled-components';
import { TextBase } from './TextBase';

interface Props {
  type?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl';
}
export const TextP = styled(TextBase.withComponent('p'))<Props>`
  font-size: ${({ type }) =>
    type === 'xl'
      ? '1rem'
      : type === 'xs'
      ? '0.75rem'
      : type === 'sm'
      ? '0.875rem'
      : type === '2xl'
      ? '1.5rem'
      : type === '3xl'
      ? '1.125rem'
      : 'inherit'};
  line-height: ${({ type }) =>
    type === 'base' ? 1.6 : type === 'xl' ? 1.2 : type === '3xl' ? 1.15 : 1.6};

  @media (min-width: 1000px) {
    font-size: ${({ type }) =>
      type === 'xl'
        ? '1.25rem'
        : type === 'xs'
        ? '0.75rem'
        : type === 'sm'
        ? '0.875rem'
        : type === 'lg'
        ? '1.125rem'
        : type === '2xl'
        ? '1.5rem'
        : type === '3xl'
        ? '2rem'
        : 'inherit'};
  }
`;
