import styled from 'styled-components';
import { SectionBgImage } from '@/atomic/atoms/Section';
import { TextH1, TextP } from '@/atomic/quanta/Text';

interface BgImageProps {
  bgImage: string;
  bgImageMobile?: string;
  bgPosition?: string;
  colorTheme?: string;
  type?: string;
}

export const DefaultTitleWrapper = styled(SectionBgImage)<BgImageProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ type }) => (type === 'short' ? '240px' : '300px')};
  padding: 0px 16px;
  margin-bottom: ${({ theme, bgImage }) =>
    theme !== 'others' || bgImage ? '32px' : '0px'};
  color: ${({ colorTheme }) => (colorTheme === 'dark' ? '#333' : '#fff')};
  text-align: center;
  background-color: ${({ theme }) =>
    theme === 'tours' ? '#ed7872' : theme === 'projects' ? '#0093bb' : '#fff'};
  @media (max-width: 768px) {
    height: 240px;
  }
`;

export const DefaultTitleElement = styled(TextH1)`
  font-size: 36px;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

export const DefaultDescription = styled(TextP)`
  margin-top: 8px;
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
