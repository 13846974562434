import styled from 'styled-components';
import { TextBase } from './TextBase';

interface Props {
  type?: 'hero' | 'xs' | 'sm' | 'xl';
}
export const TextH1 = styled(TextBase.withComponent('h1'))<Props>`
  font-size: ${({ type }) =>
    type === 'hero'
      ? 2.25
      : type === 'xl'
      ? 2.25
      : type === 'sm'
      ? 1.6
      : type === 'xs'
      ? 4
      : 3}rem;
  line-height: ${({ type }) => (type === 'sm' ? 1.6 : 1.1)};

  @media (min-width: 1000px) {
    font-size: ${({ type }) =>
      type === 'hero'
        ? 3.875
        : type === 'xl'
        ? 3.75
        : type === 'sm'
        ? 2.8
        : type === 'xs'
        ? 2.25
        : 3}rem;
  }
`;
