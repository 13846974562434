import React from 'react';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import useLocale from '@/hooks/useLocale';

export const FONT_WEIGHTS = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
} as const;
const getFont = (
  family: string,
  ...weights: Array<keyof typeof FONT_WEIGHTS>
) =>
  `family=${family.replace(/ /g, '+')}:wght@${weights
    .map((key) => FONT_WEIGHTS[key])
    .filter(Boolean)
    .join(';')}`;
const ROBOTO = getFont(
  'Roboto',
  'thin',
  'light',
  'regular',
  'medium',
  'bold',
  'black',
);
const NOTO_SANS_JP = getFont(
  'Noto Sans JP',
  'thin',
  'light',
  'regular',
  'medium',
  'bold',
  'black',
);
const GOOGLE_WEBFONT = `https://fonts.googleapis.com/css2?${[
  ROBOTO,
  NOTO_SANS_JP,
].join('&')}&display=swap`;

export default function GlobalStyle() {
  const { language } = useLocale();
  return (
    <>
      <Helmet>
        <html lang={language} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link rel="stylesheet" href={GOOGLE_WEBFONT} />
        <link rel="stylesheet" href="/destyle.css" />
      </Helmet>
      <GlobalCss />
    </>
  );
}

export const GlobalCss = createGlobalStyle`
  html,
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
    font-family: "Roboto", sans-serif;
  }
  html[lang="ja"] {
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    & body {
      font-family: inherit;
    }
  }
  svg {
    fill: currentColor;
  }
  a,
  button {
    cursor: pointer;
  }
`;
