/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    gaData?: {
      [key: string]: {
        ['first_hit']: number;
        ['hitcount']: number;
      };
    };
  }
}

// Disable each GA id when the user clicks the `Disable Google Analytics` link
export function gaOptout() {
  if (typeof window === 'undefined' || !window.gaData) return;
  const gaIds = Object.keys(window.gaData);
  gaIds.forEach(function (id) {
    const disableStr = `ga-disable-${id}`;
    document.cookie = `${disableStr}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
    window[disableStr as any] = true as any;
  });
}

// On page load, check for the disable cookie - if it is set to true, disable the GA ids
export function checkAndDisableGaOnLoad() {
  if (typeof window === 'undefined' || !window.gaData) return;
  const gaIds = Object.keys(window.gaData);
  gaIds.forEach(function (id) {
    const disableStr = `ga-disable-${id}`;
    if (document.cookie.indexOf(`${disableStr}=true`) > -1) {
      window[disableStr as any] = true as any;
    }
  });
}
