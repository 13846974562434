import styled from 'styled-components';
import { TextBase } from './TextBase';

interface Props {
  type?: 'base' | 'sm' | 'lg' | 'xl';
}
export const TextH2 = styled(TextBase.withComponent('h2'))<Props>`
  font-size: ${({ type }) =>
    type === 'xl'
      ? 2.375
      : type === 'sm'
      ? 1.875
      : type === 'lg'
      ? 1.2
      : 1.75}rem;
  line-height: 1.22;
  @media (min-width: 1000px) {
    font-size: ${({ type }) =>
      type === 'xl'
        ? 4
        : type === 'sm'
        ? 1.875
        : type === 'lg'
        ? 2.625
        : 2.25}rem;
  }
`;
