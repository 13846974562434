import styled from 'styled-components';
import { SectionWrap } from './SectionWrap';

interface Props {
  bgImage: string;
  bgImageMobile?: string;
  bgPosition?: string;
}
export const SectionBgImage = styled(SectionWrap)<Props>`
  background-image: ${({ bgImageMobile }) =>
    bgImageMobile ? `url(${bgImageMobile})` : 'none'};
  background-repeat: no-repeat;
  background-position: ${({ bgPosition }) => bgPosition || 'center'};
  background-size: cover;

  @media (min-width: 1000px) {
    background-image: url(${({ bgImage }) => bgImage});
  }
`;
