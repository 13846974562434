import React from 'react';
import { Helmet } from 'react-helmet';
import useLangRedirect from '../hooks/useLangRedirect';
import usePageContext from '../hooks/usePageContext';
import { checkAndDisableGaOnLoad, gaOptout } from '../lib/gaOptout';
import { Footer } from '../organisms/Footer';
import { Header } from '../organisms/Header';
import WebpProvider from '../providers/WebpProvider';
import { FontStyle } from './GlobalThemes';
import SEO from './SEO';

const TemplateWrapper: React.FC = ({ children }) => {
  useLangRedirect();
  const { title, canonical } = usePageContext();

  return (
    <WebpProvider>
      <Helmet>
        {/* Add ability for users to opt out of GA tracking */}
        <script>{`${(checkAndDisableGaOnLoad(), gaOptout)}`}</script>
      </Helmet>
      <FontStyle />
      <SEO title={title} canonical={canonical} />
      <Header />
      {children}
      <Footer />
    </WebpProvider>
  );
};

export default TemplateWrapper;
