import React from 'react';
import {
  DefaultDescription,
  DefaultTitleElement,
  DefaultTitleWrapper,
} from './DefaultTitle.style';

type Props = {
  title?: string;
  description?: string;
  imageUrl?: string;
  theme?: string;
  colorTheme?: string;
  type?: string;
};

export const DefaultTitle: React.FC<Props> = ({
  title,
  description,
  imageUrl,
  theme,
  colorTheme,
  type,
}) => {
  return (
    <DefaultTitleWrapper
      bgImage={imageUrl ?? ''}
      bgImageMobile={imageUrl ?? ''}
      theme={theme || 'others'}
      colorTheme={colorTheme || 'dark'}
      type={type || ''}
    >
      {title && <DefaultTitleElement>{title}</DefaultTitleElement>}
      {description && <DefaultDescription>{description}</DefaultDescription>}
    </DefaultTitleWrapper>
  );
};
