import React from 'react';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
import renderHtml from '../../lib/renderHtml';
import { DefaultTitle } from '../DefaultTitle';
import {
  DefaultContent,
  DefaultInnerWrapper,
  DefaultOuterWrapper,
} from './Default.style';

type Props = {
  data: DataContainsNode;
};
export const Default: React.FC<Props> = ({ data }) => {
  const { html } = useTransformNode(data);
  const { frontmatter } = useTransformNode(data);
  return (
    <DefaultOuterWrapper data-cy={`${frontmatter?.cyTitle}Page`}>
      <DefaultTitle
        title={frontmatter?.title || ''}
        description={frontmatter?.description}
        colorTheme={frontmatter?.colorTheme}
        theme={frontmatter?.theme}
      />
      <DefaultInnerWrapper>
        <DefaultContent>{renderHtml(html)}</DefaultContent>
      </DefaultInnerWrapper>
    </DefaultOuterWrapper>
  );
};
