import React from 'react';
import { graphql } from 'gatsby';
import { DataContainsNode } from '@/hooks/useTransformer';
import { Default } from '../components/Default';
import Layout from '../components/Layout';

export const pageQuery = graphql`
  query Default($slug: String!) {
    allMarkdownRemark(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          id
          html
          fields {
            language
            country
            slug
          }
          frontmatter {
            templateKey
            title
            cyTitle
            colorTheme
            description
            theme
          }
        }
      }
    }
  }
`;

type Props = {
  data: DataContainsNode;
};

const DefaultPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout>
      <Default data={data} />
    </Layout>
  );
};

export default DefaultPage;
