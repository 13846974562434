import styled from 'styled-components';
import { TextBase } from './TextBase';

interface Props {
  type?: 'sm' | 'base' | 'lg' | 'xl' | '2xl';
}
export const TextH3 = styled(TextBase.withComponent('h3'))<Props>`
  font-size: ${({ type }) =>
    type === 'lg'
      ? 1.25
      : type === 'sm'
      ? 1.25
      : type === 'xl'
      ? 2.25
      : type === '2xl'
      ? 2.5
      : 1.75}rem;
  line-height: 1.166;
  @media (min-width: 1000px) {
    font-size: ${({ type }) =>
      type === 'lg'
        ? 1.875
        : type === 'sm'
        ? 1.25
        : type === 'xl'
        ? 2.25
        : type === '2xl'
        ? 2.5
        : 1.5}rem;
  }
`;
