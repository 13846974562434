import styled from 'styled-components';

interface Props {
  maxWidth?: 'wide' | 'narrow' | 'none';
}
export const SectionContainer = styled.div<Props>`
  box-sizing: border-box;
  width: 100%;
  max-width: ${({ maxWidth }) =>
    maxWidth === 'wide'
      ? '1248px'
      : maxWidth === 'narrow'
      ? '1008px'
      : maxWidth === 'none'
      ? 'none'
      : '1176px'};
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto;
`;
