import styled from 'styled-components';

interface Props {
  margin?: 'narrow' | 'wide' | 'wider' | 'none';
  bgColor?: string;
}
export const SectionWrap = styled.section<Props>`
  position: relative;
  display: block;
  width: 100%;
  padding-top: ${({ margin }) =>
    margin === 'narrow'
      ? 48
      : margin === 'wide'
      ? 48
      : margin === 'wider'
      ? 96
      : margin === 'none'
      ? 0
      : 32}px;
  padding-bottom: ${({ margin }) =>
    margin === 'narrow'
      ? 48
      : margin === 'wide'
      ? 48
      : margin === 'wider'
      ? 96
      : margin === 'none'
      ? 0
      : 32}px;
  background-color: ${({ bgColor }) => bgColor || 'initial'};
  @media (min-width: 1000px) {
    padding-top: ${({ margin }) =>
      margin === 'narrow'
        ? 48
        : margin === 'wide'
        ? 72
        : margin === 'wider'
        ? 128
        : margin === 'none'
        ? 0
        : 64}px;
    padding-bottom: ${({ margin }) =>
      margin === 'narrow'
        ? 48
        : margin === 'wide'
        ? 72
        : margin === 'wider'
        ? 128
        : margin === 'none'
        ? 0
        : 64}px;
  }
`;
