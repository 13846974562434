import styled from 'styled-components';
import { FONT_WEIGHTS } from '@/components/GlobalStyle';

interface Props {
  fontWeight?: keyof typeof FONT_WEIGHTS | 'inherit';
  lineHeight?: number | string;
  color?: string;
}
export const TextBase = styled.p<Props>`
  margin: 0;
  font-size: 1rem;
  font-weight: ${({ fontWeight }) =>
    !fontWeight || fontWeight === 'inherit'
      ? 'inherit'
      : FONT_WEIGHTS[fontWeight]};
  ${({ lineHeight }) =>
    lineHeight == null ? '' : `line-height: ${lineHeight};`}
  color: ${({ color }) => color || 'inherit'};
  white-space: pre-wrap;
  > span {
    display: inline-block;
  }
`;
