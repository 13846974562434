import styled from 'styled-components';

export const DefaultOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 120px;
`;

export const DefaultInnerWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  max-width: 960px;
  @media (max-width: 1024px) {
    width: 90%;
  }
`;

export const DefaultContent = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
  color: #333;
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    font-weight: bold;
  }
  > h1 {
    font-size: 20px;
    font-weight: 700;
  }
  > h2 {
    margin-top: 24px;
    margin-bottom: 0px;
    font-size: 24px;
  }
  > h3 {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 1.17em;
  }
  > p {
    margin-top: 0px;
    margin-bottom: 24px;
  }
  > ul,
  > ol {
    margin-top: 16px;
    margin-bottom: 16px;
    padding-inline-start: 40px;
  }
  > ul {
    list-style-type: disc;
  }
  > ol {
    list-style-type: decimal;
  }
  a {
    color: #0093bb;
    text-decoration: none;
  }
  > table {
    width: 100%;
    margin-bottom: 24px;
    border-spacing: 0px;
    border-right: 1px solid #d0d7de;
    border-bottom: 1px solid #d0d7de;
    border-left: 1px solid #d0d7de;
  }
  tr {
    &:nth-child(2n) {
      background-color: #f6f8fa;
    }
  }
  th,
  td {
    padding: 8px 16px;
    border-top: 1px solid #d0d7de;
    &:nth-child(2n) {
      border-right: 1px solid #d0d7de;
      border-left: 1px solid #d0d7de;
    }
  }
`;
