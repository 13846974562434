import 'fontsource-roboto/300.css';
import 'fontsource-roboto';
import 'fontsource-noto-sans-jp';
import 'fontsource-noto-sans-jp/300.css';
import { createGlobalStyle } from 'styled-components';

export const FontStyle = createGlobalStyle`
    body {
        margin: 0;
        font-family: Roboto, "Noto Sans JP", Arial, sans-serif;
        color: #251e1c;
    }
`;
